import restaurant from "./images/r1.jpg";
import biserica from "./images/b.jpg";
import img_card from "./images/f.jpg";
import imgheadermb from "./images/ip4.jpg";
import imgheader from "./images/ip4.jpg";
import imgheadermiini from "./images/mr.jpg";
import imgheadermiinimb from "./images/mr.jpg";
import logo from './images/stea.png'



const data = {
    introData: [{
        copilul: "Jonathan ",
        familia: "fam. Buzila",
        logo: logo,
        tata: "Dumitru",
        mama: "Veronica",
        data: "22 Aprilie 2023",
        data_confirmare: "5 aprilie 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "veronikamarin5@gmail.com", 
       tel: "+33783265841",
       phone: "tel:+33783265841",
       viber: "viber://chat?number=%2B33783265841",
       whatsapp: "https://wa.me/+33783265841",
       messenger: "https://www.messenger.com/t/veronika.marin.773",
       tel1: "+40751786051",
       phone1: "tel:+40751786051",
       viber1: "viber://chat?number=%2B40751786051",
       whatsapp1: "https://wa.me/+4075178605188",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica",
            name: "Saint Séraphin de Sarov",
            data: "22 aprilie 2023, sâmbătă, ora 12:00",
            adress: "91 Rue du Moulin de Senlis, 91230 Montgeron, France",
            harta: "https://goo.gl/maps/JyknXHmbdggSeBdd9",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Château du Bois Éluis",
            data: "22 aprilie 2023, sâmbătă, ora 16:00",
            adress: "17 Rue de Bois Eluis, 77320 Dagny, France",
            harta: "https://goo.gl/maps/ixHgnKKHhriNBg3S7",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar prețios de la Dumnezeu, iar botezul este grija cu care Dumnezeu ni-l oferă pe viață!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;