
import React from "react";
import data from "./Data.js"
import Card from "./components/Card";

const CardLocal = () => {
    return (
        <section id="geolocal" className="" >
            <div className="card_local py-3 py-lg-5 container" >
            <h2 className="text-center mt-2" style={{ fontFamily: 'Dancing Script', fontSize: '40px', color: 'black' }}>Unde și Când?</h2>
            <div className="row justify-content-center mb-5"  >

                {data.cardData.map((item, index) => {
                    return (
                   
                        <Card key={index} img={item.img} title={item.title} localul={item.localul} name={item.name} data={item.data} adress={item.adress} harta={item.harta}  />
                    
                    
                    )
                })}

</div>
            </div>
        </section>


    )


}

export default CardLocal;